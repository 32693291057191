<template>
    <div class="view-pop" v-show="show">
        <div class="content">
            <div class="title">评价发布TIPS</div>
            <div class="sub-title">一碗福州欢迎大家积极参与发布真实有用的评价，为保证评价的真实有效，违反以下评价规则的内容将不与展示：</div>
            <div class="rule">1.有违法律法规，公序良俗的内容；</div>
            <div class="rule">2.含有网页链接、微信号、手机号、QQ号、二维码和广告营销信息；</div>
            <div class="rule">3.受利益诱导发布的指定评价内容；</div>
            <div class="rule">4.虚构、引人误解、与服务商品无关的评价内容；</div>
            <div class="rule">5.冒用他人身份或盗用他人图片、文字等侵犯他们合法权益的评价。</div>
            <div class="btn" @click="clickClose">我已知晓</div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'CommentAlert',
    data () {
      return {
        show: false
      }
    },
    methods: {
      showPop: function () {
        this.show = true
      },
      clickClose: function () {
        this.show = false
        this.$emit('toComment')
      }
    }
  }
</script>

<style scoped>
    .view-pop {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        /* z-index: 9; */
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }
    .content {
        width: 85%;
        background-color: #FFFFff;
        padding: 2rem 2rem;
        box-sizing: border-box;
        border-radius: 1rem;
    }
    .title {
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 1rem;
        color: #43403E;
    }
    .sub-title {
        font-size: 1rem;
        color: #D7B07E;
    }
    .rule {
        color: #747474;
        margin-top: 0.5rem;
    }
    .btn {
        margin: 2rem auto 0;
        border-radius: 10rem;
        padding: 0.5rem;
        width: 13rem;
        font-size: 1.4rem;
        text-align: center;
        color: #FFFFff;
        background: #D7B07E;
    }
</style>
