<template>
    <div class="body">
        <!--<div class="rule-icon">评选规则</div>-->
        <div class="list-box">
            <div style="position: absolute;bottom: 0;left: 0;width: 100%;background-color: rgba(45,45,45, 0.64);">
                <div class="list-box-top">
                    <div class="list-box-top1">
                        <img src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/zuobiao221120.png"/>
                    </div>
                    <div>
                        <div>2021-2022一碗福州金碗奖</div>
                    </div>
                </div>
                <!-- 新版tab，样式太复杂干脆直接写死在前端 -->
                <div class="m-tab">
                    <div style="display: flex;justify-content: space-around;">
                        <!--顶餐标签-->
                        <img class="m-tab-top" v-if="awradId === 71" @click="switchTab(71)" style="float: left" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/111%E9%A1%B6%E9%A4%90%E9%80%89%E4%B8%AD.png">
                        <img class="m-tab-top" v-else @click="switchTab(71)" style="float: left" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/%E9%A1%B6%E7%BA%A7%E6%B2%A1%E9%80%89%E4%B8%AD.png">
                        <!--热门标签-->
                        <img class="m-tab-top" v-if="awradId === 75" @click="switchTab(75)" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/%E7%83%AD%E9%97%A8%E9%80%89%E4%B8%AD.png">
                        <img class="m-tab-top" v-else @click="switchTab(75)" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/111%E7%83%AD%E9%97%A8%E6%B2%A1%E9%80%89%E4%B8%AD.png">
                    </div>
                    <div style="display: flex;justify-content: space-around;margin-top: 0.5rem;">
                        <img class="m-tab-bottom" v-if="awradId === 76" @click="switchTab(76)" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/111%E5%B9%B4%E5%BA%A6%E9%80%89%E4%B8%AD.png">
                        <img class="m-tab-bottom" v-else @click="switchTab(76)" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/%E8%BE%97%E9%80%89%E6%B2%A1%E9%80%89%E4%B8%AD.png">
                        <!--大众喜爱标签-->
                        <img class="m-tab-bottom" v-if="awradId === 77" @click="switchTab(77)" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/111%E5%A4%A7%E4%BC%97%E9%80%89%E4%B8%AD.png">
                        <img class="m-tab-bottom" v-else @click="switchTab(77)" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/%E5%96%9C%E7%88%B1%E6%B2%A1%E9%80%89%E4%B8%AD.png">
                        <!--闽菜标签-->
                        <img class="m-tab-bottom" v-if="awradId === 80" @click="switchTab(80)" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/111%E9%97%BD%E8%8F%9C%E9%80%89%E4%B8%AD.png">
                        <img class="m-tab-bottom" v-else @click="switchTab(80)" src="https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/%E6%96%B0%E8%A7%92%E6%A0%87/%E9%97%BD%E8%8F%9C%E6%B2%A1%E9%80%89%E4%B8%AD.png">
                    </div>
                    <!--甄选标签-->
                    <div class="rank-tip">*以下商家排名不分先后</div>
                </div>
            </div>
            <!-- 分类 -->
            <!--            <div class="class-wrapper">-->
            <!--                <div class="class-tag">分类</div>-->
            <!--                <div class="class-item-wrapper">-->
            <!--                    <div>闽菜1</div>-->
            <!--                    <div class="class-item-wrapper-active">闽菜1</div>-->
            <!--                    <div>闽菜1</div>-->
            <!--                    <div>闽菜1</div>-->
            <!--                    <div>闽菜1</div>-->
            <!--                    <div>闽菜1</div>-->
            <!--                    <div>闽菜1</div>-->
            <!--                    <div>闽菜1</div>-->
            <!--                    <div>闽菜1</div>-->
            <!--                    <div>闽菜1</div>-->
            <!--                </div>-->
            <!--            </div>-->
<!--            <div class="list-box-choose">-->
<!--                <div class="list-box-choose1">-->
<!--                    <div :class="item.id == awradId ?  'list-box-choose3' : 'list-box-choose2' " v-for="(item,index) in tabList" :key="index" @click="switchItems(item)">-->
<!--                        <img :src="item.id == awradId ? item.icon_act : item.icon">-->
<!--                        <div>{{index}}</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="list-content" v-for="(item,index) in businessList" :key="index">
            <div class="list-content-top"  @click="viewBusiness(item.business_id)">
                <img class="list-content-top-tip" :src="tabImg">
                <!--<img class="list-content-top-xin" src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-2022110505.png">-->
                <img class="list-content-top-banner" :src="item.img1">
            </div>
            <div  class="list-content-bottom">
                <div class="list-content-bottom-title">
                    <div>{{item.name}}</div>
                    <div class="list-content-bottom-money" v-if="item.per_capita > 0">￥<span>人均{{item.per_capita}}元</span></div>
                </div>
                <div class="list-content-bottom-details" v-if="item.reason">
                    <div class="list-content-bottom-details-1">上榜理由</div>
                    <div style="color: #ffffff;">{{item.reason}}</div>
                </div>
                <div class="list-content-bottom-details">
                    <div class="list-content-bottom-details-1">推荐指数</div>
                    <div>
                        <van-rate v-model="item.show_star"
                                  :size="16"
                                  color="#D7B07E"
                                  void-icon="star"
                                  void-color="#eee" readonly
                                  allow-half
                        />
                    </div>
                </div>

                <div class="list-content-bottom-comment">
                    <div class="list-content-bottom-comment1" @click="clickComment(item.business_id)">
                        <div class="list-content-bottom-comment1-write">说点什么吧~</div>
                        <div class="list-content-bottom-comment1-icon">
                            <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-20221110503.png"/>
                            <div></div>
                        </div>
                    </div>
                    <div @click="showCustomerServicePop()" class="list-content-bottom-subscribe">我要预约</div>
                </div>
            </div>
        </div>
        <!--预约客服弹窗-->
        <customer-service-honor ref="customerServiceHonor"></customer-service-honor>
        <!--评论提示-->
        <comment-alert ref="commentAlert" @toComment="viewCommentList"></comment-alert>
    </div>
</template>

<script>
    import { globalConfig } from '../../utils/mixin'
    import CustomerServiceHonor from '../../components/common/CustomerServiceHonor'
    import CommentAlert from '../../components/honor/CommentAlert'

    export default {
        name: 'Comment',
        mixins: [globalConfig],
        components: { CustomerServiceHonor, CommentAlert },
        data () {
            return {
                awradId: 1,
                config: [],
                title: '',
                tabList: [],
                tabImg: '',
                businessList: [],
                show_star: 5,
                lastClickBusiness: ''
            }
        },
        methods: {
          // 22.11.20UI改版这个函数用来替换switchItems
          switchTab: function (id) {
            //  闽菜先隐藏
            if (id === 80) {
              this.$toast('尽情期待')
              return
            }
            //  找到tag
            const subList = this.config.list['2021-2022一碗福州金碗奖']
            let subConfig = {}
            for (const key in subList) {
              if (subList[key].id === id) {
                subConfig = subList[key]
              }
            }
            console.log('选中的', subList, subConfig)
            this.tabImg = subConfig.tag
            this.awradId = id
            this.getList(id)
          },
          switchItems: function (e) {
                console.log(e)
                this.awradId = e.id
                this.tabImg = e.tag
                this.getList(e.id)
            },
            init: function () {
                this.axios.post(this.apiUrl + 'mall/top_rank/getTopRankConfig').then((response) => {
                    this.config = response.data.config
                    //  配置生效，请求第一个
                    this.awradId = this.config.default.id
                    this.title = this.config.default.name
                    this.tabList = this.config.list[this.title]
                    this.tabImg = this.config.default.tag
                    // this.tabList.forEach(function (value, index, array) {
                    //     console.log(index)
                    //
                    // })
                    console.log('123', this.title, this.config.list)
                    console.log(this.awradId)
                    console.log(this.title)
                    console.log(this.tabList)
                    this.getList(this.config.default.id)
                })
            },
            getList: function (id) {
                this.axios.post(this.apiUrl + 'mall/top_rank/getTopRankList', {
                    awradId: id
                }).then((response) => {
                    console.log(response)
                    this.businessList = response.data.list
                })
            },
            viewBusiness: function (id) {
                this.$router.push('/honor/newStore/' + id)
            },
            // 点击评论
            clickComment: function (id) {
                this.$refs.commentAlert.showPop()
                this.lastClickBusiness = id
            },
            viewCommentList: function (id) {
                this.$router.push('/commentList/1?pid=' + this.lastClickBusiness)
            },
            // 展示客服面板
            showCustomerServicePop: function () {
                this.$refs.customerServiceHonor.showPop()
            }
        },
        created () {
            this.init()
        }
    }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .body{
        width: 100vw;
        height: 100vh;
        position: absolute;
        background-color: #000000;
    }
    .rule-icon{
        position: absolute;
        margin: 20vw 0 0 78vw;
        width: 22vw;
        height: 10vw;
        line-height: 10vw;
        text-align: center;
        border-radius:  10vw 0 0 10vw;
        border: 1px solid #fff;
        color: #fff;
        font-size: 3.4vw;
        border-right: none;
        padding-left: 1vw;
    }
    .list-box{
        width: 100vw;
        min-height: 100vw;
        height: 45rem;
        /*background: rgba(45,45,45, 0.64);*/
        border-radius: 5vw 5vw 0 0;
        /*margin-top: 117vw;*/
        color: #fff;
        background: url("https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/rank_top/123.jpg") no-repeat #000;
        background-size: 100vw;
        position: relative;
    }
    .list-box-top{
        display: flex;
        width: 100vw;
        height: 18vw;
        justify-content: space-between;
        padding: 0 6vw;
        align-items: center;
        font-size: 3.8vw;
    }
    .list-box-top1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 5.2vw;
        font-weight: 600;
    }
    .list-box-top1 img{
        height: 1rem;
        width: 3rem;
        margin-right: 2vw;
    }
    .list-box-rank{
        width: 10vw;
    }
    .list-box-choose{
        width: 100vw;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 2vw;
        margin-bottom: 5vw;
    }
    .list-box-choose1{
        width: 100vw;
        display: flex;
        align-items: center;
    }
    .list-box-choose2{
        display: flex;
        min-width: 36vw;
        justify-content: center;
        align-items: center;
        padding: 2vw 1.8vw;
        font-size: 3.2vw;
    }
    .list-box-choose3{
        display: flex;
        min-width: 36vw;
        justify-content: center;
        align-items: center;
        padding: 2vw 1.8vw;
        font-size: 3.2vw;
        border: 1px solid #5B5B5B;
        border-radius: 3vw;
    }
    .list-box-choose1 img{
        width: 5vw;
        height: 4vw;
        margin-right: 1vw;
    }
    .list-content{
        background: rgba(31,31,31, 1);
    }
    .list-content{
        padding-bottom: 6vw;
    }
    .list-content-top{
        width: 90vw;
        margin: 0 5vw;
        padding: 6vw 0 4vw 0 ;
        position: relative;
    }
    .list-content-top-tip{
        position: absolute;
        margin: 0 0 0 3vw;
        width: 30vw;
    }
    .list-content-top-xin{
        position: absolute;
        margin: 7vw 0 0 78vw;
        width: 7.2vw;
    }
    .list-content-top-banner{
        width: 100%;
        margin-top: 3vw;
    }
    .list-content-bottom{
        width: 90vw;
        margin: 0 5vw;
    }
    .list-content-bottom-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 4.6vw;
    }
    .list-content-bottom-title div {
        color: #ffffff;
    }
    .list-content-bottom-title div:first-child {
        font-weight: 600;
    }
    .list-content-bottom-money{
        font-size: 3vw;
    }
    .list-content-bottom-money span{
        font-size: 4.2vw;
    }
    .list-content-bottom-details{
        display: flex;
        align-items: center;
        margin: 3vw 0;
        font-size: 13px;
    }
    .list-content-bottom-details-1{
        padding: 0.2rem 0.7rem;
        color: #333;
        font-size: 3vw;
        border-radius: 5vw;
        background: #D7B07E;
        margin-right: 3vw;
    }
    .list-content-bottom-comment{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .list-content-bottom-comment1{
        display: flex;
        align-items: center;
    }
    .list-content-bottom-comment1-write{
        padding: 2vw 6vw;
        margin-right: 2vw;
        width: 40vw;
        border-radius: 8vw;
        font-size: 3.6vw;
        background: #2D2D2F;
        color: #ffffff;
    }
    .list-content-bottom-comment1-icon{
        display: flex;
        align-items: center;
    }
    .list-content-bottom-comment1-icon img{
        width: 5vw;
        height: 5vw;
    }
    .list-content-bottom-comment1-icon div{
        font-size: 2.8vw;
        margin-top: 3vw;
    }
    .list-content-bottom-subscribe{
       padding: 2vw 6vw;
        border-radius: 5vw;
        color: #333;
        background: #D7B07E;
        font-weight: 600;
    }

    /*  2022.10.20 新版的tab样式  */
    .m-tab-top {
        width: 12rem;
        height: 4rem;
        /*margin-left: 1rem;*/
    }

    .m-tab-bottom {
        width: 8rem;
        height: 4rem;
        /*margin-left: 0.5rem;*/
    }

    .class-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin: 1rem 0;
    }

    .class-tag {
        font-size: 1.1rem;
        color: #D7B07E;
        border: 1px solid #D7B07E;
        border-radius: 10rem;
        margin-left: 1rem;
        margin-right: 1rem;
        padding: 0 0.5rem;
    }

    .class-item-wrapper {
        width: 20rem;
        overflow-x: scroll;
        display: flex;
        flex-flow: row;
    }

    .class-item-wrapper div {
        min-width: 4rem;
        text-align: center;
    }
    .class-item-wrapper-active {
        color: #D7B07E;
    }
    .rank-tip {
        font-size: 0.9rem;
        color: #999999;
        margin-top: 0.5rem;
        position: relative;
        left: 16rem;
        top: 1.5rem;
    }
</style>
